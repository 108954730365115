import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { DocumentData } from 'firebase/firestore';
import lodash from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, from, Observable, of, zip } from 'rxjs';
import { concatMap, finalize, map, take } from 'rxjs/operators';
import {
    BENEFITS_SERVICES_COLLECTION,
    BOOKING_KEY_FOR_BKV_CARD,
    BOOKING_KEY_FOR_COLLECTIVE_AGREEMENTS_CARD,
    BOOKKEEPING_KEYS_COLLECTION,
    COLLECTIVE_AGREEMENT_COLLECTION,
    COMPANIES_COLLECTION,
    OP_EQUALS,
    OTHER_SERVICES_CARD,
    PAYROLL_COLLECTION,
    QUESTIONS_COLLECTION,
    SERVICES_CARD,
    STATUS_ACTIVE,
    UPDATE_BOOKKEEPING_KEY_FUNCTION,
    WAGE_ACCOUNTING_SYSTEM_COLLECTION,
    WAGE_TAX_FREE_TYPES_CARD,
    WAGE_TYPES_CARD,
} from 'src/app/app.constants';
import { BaseService } from 'src/app/core/abstractions/base-service';
import { UserService } from 'src/app/core/services/user.service';
import { AccountingSystemsEnum } from 'src/app/shared/enums/accounting-systems.enum';
import { FilterModel } from 'src/app/shared/models/filter.model';
import { FireLoggingService } from '../../../../../core/services/fire-logging.service';

export type ImportSystemType = 'datev' | 'lexware' | undefined;

@Injectable({
    providedIn: 'root',
})
export class BookkeepingKeyService extends BaseService<any> {
    constructor(
        private userService: UserService,
        private spinner: NgxSpinnerService,
        protected firestore: AngularFirestore,
        protected http: HttpClient,
        protected functions: Functions,
        protected fireLogging: FireLoggingService
    ) {
        super(firestore, http, functions, fireLogging);
    }

    fetchAllKeys(importSystemType?: AccountingSystemsEnum, companyId?: string) {
        companyId ??= this.userService.loggedUser.companyId;
        this.spinner.show('bookkeeping-keys-spinner');
        let obs: Observable<AccountingSystemsEnum>;
        if (importSystemType) {
            obs = of(importSystemType);
        } else {
            obs = this.getImportSystemType(companyId);
        }
        return obs.pipe(
            concatMap((type) =>
                forkJoin({
                    services: this.getBenefitServices(companyId, type),
                    keys: this.getBookkeepingKeys(companyId, type),
                }).pipe(
                    take(1),
                    finalize(() => {
                        this.spinner.hide('bookkeeping-keys-spinner');
                    })
                )
            )
        );
    }

    fetchAllKeysGroupedByCard(companyId?: string) {
        companyId ??= this.userService.loggedUser.companyId;

        return this.getImportSystemType(companyId).pipe(
            concatMap((importSystemType) =>
                this.fetchAllKeys(importSystemType).pipe(
                    map(({ services, keys }) => {
                        const anotherCards = lodash
                            .chain(keys)
                            .groupBy('card')
                            .value();
                        const wageTypes = anotherCards[WAGE_TYPES_CARD];
                        const wageTaxFreeTypes =
                            anotherCards[WAGE_TAX_FREE_TYPES_CARD];
                        const otherServices = anotherCards[OTHER_SERVICES_CARD];
                        const bookingKeyForCollectiveAgreements =
                            anotherCards[
                                BOOKING_KEY_FOR_COLLECTIVE_AGREEMENTS_CARD
                            ];
                        const bookingKeyForBkv = anotherCards[
                            BOOKING_KEY_FOR_BKV_CARD
                        ];
                        return {
                            services,
                            wageTypes,
                            wageTaxFreeTypes,
                            otherServices,
                            bookingKeyForCollectiveAgreements,
                            bookingKeyForBkv,
                        };
                    })
                )
            )
        );
    }

    getPayrollCollectiveAgreement(): Observable<any> {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_EQUALS,
                value: STATUS_ACTIVE,
            },
        ];
        return this.search(
            `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${QUESTIONS_COLLECTION}/${PAYROLL_COLLECTION}/${COLLECTIVE_AGREEMENT_COLLECTION}`,
            filter
        ).pipe(
            map((res) => {
                return res[0];
            })
        );
    }

    getImportSystemType(companyId: string): Observable<AccountingSystemsEnum> {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_EQUALS,
                value: STATUS_ACTIVE,
            },
        ];
        return this.search(
            `${COMPANIES_COLLECTION}/${companyId}/${QUESTIONS_COLLECTION}/payroll/${WAGE_ACCOUNTING_SYSTEM_COLLECTION}`,
            filter
        ).pipe(
            take(1),
            map((res) => res[0]?.type)
        );
    }

    getBenefitServices(
        companyId: string,
        importSystemType: AccountingSystemsEnum
    ) {
        return this.search(
            this.getBaseCollectionURL(companyId, BENEFITS_SERVICES_COLLECTION)
        ).pipe(
            concatMap((items: Array<any>) => {
                return zip(
                    ...items.map((item) =>
                        this.handleServiceData(item, importSystemType)
                    )
                );
            }),
            take(1)
        );
    }

    getBookkeepingKeys(
        companyId: string,
        importSystemType: AccountingSystemsEnum
    ) {
        return this.search(
            this.getBaseCollectionURL(companyId, BOOKKEEPING_KEYS_COLLECTION)
        ).pipe(
            concatMap((items: Array<any>) => {
                return zip(
                    ...items.map((item) =>
                        this.handleBookkeepingKeysData(item, importSystemType)
                    )
                );
            }),
            take(1)
        );
    }

    saveKeys(changes) {
        return httpsCallableData<any, any>(
            this.functions,
            UPDATE_BOOKKEEPING_KEY_FUNCTION
        )({
            services: changes,
        }).pipe(take(1));
    }

    private handleServiceData(item, importSystemType: AccountingSystemsEnum) {
        return from(item.service.get()).pipe(
            map((service: DocumentData) => {
                const serviceData = service.data();
                if (serviceData) {
                    item.nameEn = serviceData.nameEn;
                    item.nameDe = serviceData.nameDe;
                    item.icon = serviceData.icon;
                    item.card = serviceData.card || SERVICES_CARD;
                    item.isTaxable = !!serviceData.isTaxable;
                    item.bookkeepingDescriptionDe =
                        serviceData.bookkeepingDescriptionDe;
                    item.bookkeepingDescriptionEn =
                        serviceData.bookkeepingDescriptionEn;
                    this.transformDefaultKeys(
                        item,
                        serviceData,
                        importSystemType
                    );
                }
                item.code = item.code?.map((e) => e.toString());
                return item;
            })
        );
    }

    private handleBookkeepingKeysData(
        item,
        importSystemType: AccountingSystemsEnum
    ) {
        return from(item.key.get()).pipe(
            map((key: DocumentData) => {
                const keyData = key.data();
                item.nameEn = keyData.nameEn;
                item.nameDe = keyData.nameDe;
                item.datev = keyData.datev;
                item.card = keyData.card;
                item.index = keyData.index;
                item.isTaxable = !!keyData.isTaxable;
                item.status = keyData.status;
                this.transformDefaultKeys(item, keyData, importSystemType);
                return item;
            })
        );
    }

    /**
     * Add the service/key root the default values;
     *
     * @param item - current key or service;
     * @param globalItem - Finstein standard service or key;
     * @param importSystemType - DATEV, LEXWARE or LODAS;
     */
    private transformDefaultKeys(
        item,
        globalItem,
        importSystemType: AccountingSystemsEnum
    ) {
        if (globalItem && importSystemType) {
            const {
                defaultImportKeys,
                defaultExportKeys,
                keysBlocked,
                disableAlias,
                disableDefaultExport,
                disableDefaultImport,
                disableExport,
                disableImport,
                requiredColumns,
            } = this.getDefaultValues(globalItem, importSystemType);
            item.code = item.code?.map(lodash.toString) || [];
            item.defaultImportKeys = defaultImportKeys;
            item.defaultExportKeys = defaultExportKeys;
            item.keysBlocked = keysBlocked;
            item.disableAlias = disableAlias;
            item.disableDefaultExport = disableDefaultExport;
            item.disableDefaultImport = disableDefaultImport;
            item.disableExport = disableExport;
            item.disableImport = disableImport;
            item.requiredColumns = requiredColumns;
            item.onePayment = globalItem.onePayment;

            if (globalItem.isTaxable) {
                const tax: any = this.getDefaultValues(
                    globalItem.tax,
                    importSystemType
                );
                const { code, exportableCode, alias, pendentApproval } =
                    item.tax || {};
                item.tax = {
                    nameEn: globalItem.tax.nameEn,
                    nameDe: globalItem.tax.nameDe,
                    bookkeepingDescriptionDe:
                        globalItem.tax.bookkeepingDescriptionDe,
                    bookkeepingDescriptionEn:
                        globalItem.tax.bookkeepingDescriptionEn,
                    code: code?.map(lodash.toString) || [],
                    exportableCode,
                    alias,
                    pendentApproval,
                    defaultImportKeys: tax.defaultImportKeys,
                    defaultExportKeys: tax.defaultExportKeys,
                    keysBlocked: tax.keysBlocked,
                    disableAlias: tax.disableAlias,
                    disableDefaultExport: tax.disableDefaultExport,
                    disableDefaultImport: tax.disableDefaultImport,
                    disableExport: tax.disableExport,
                    disableImport: tax.disableImport,
                    requiredColumns: tax.requiredColumns,
                    onePayment: globalItem.tax.onePayment,
                    separatedKeys: item.separatedKeys,
                };
            }
        }
    }

    getDefaultValues(globalItem, importSystemType: AccountingSystemsEnum) {
        let {
            defaultImportKeys,
            defaultExportKeys,
            keysBlocked,
            requiredColumns = [],
        } = globalItem[importSystemType.toLowerCase()];
        const {
            disableAlias = false,
            disableDefaultExport = false,
            disableDefaultImport = false,
            disableExport = false,
            disableImport = false,
        } = globalItem[importSystemType.toLowerCase()];
        /*
         * Add empty array for default values,
         * In some cases firebase returns null
         * and destruct recognizes it as a value
         */
        if (lodash.isNil(defaultImportKeys)) {
            defaultImportKeys = [];
        }
        if (lodash.isNil(defaultExportKeys)) {
            defaultExportKeys = [];
        }
        if (lodash.isNil(keysBlocked)) {
            keysBlocked = [];
        }
        if (lodash.isNil(requiredColumns)) {
            requiredColumns = [];
        }

        /*
         * Transform all values to string
         * because the ngx chips input component
         */
        defaultImportKeys = defaultImportKeys.map(lodash.toString);
        defaultExportKeys = defaultExportKeys.map(lodash.toString);
        keysBlocked = keysBlocked.map(lodash.toString);

        return {
            defaultImportKeys,
            defaultExportKeys,
            keysBlocked,
            disableAlias,
            disableDefaultExport,
            disableDefaultImport,
            disableExport,
            disableImport,
            requiredColumns,
        };
    }

    private getBaseCollectionURL(companyId: string, complement: string) {
        return `${COMPANIES_COLLECTION}/${companyId}/${complement}`;
    }

    fetchCompanyRequiredKey(requiredKey: string, companyId: string) {
        return this.getById(requiredKey, this.getBaseCollectionURL(companyId, BOOKKEEPING_KEYS_COLLECTION))
        .pipe(
            map((res)=> {
                delete res.key;
                return res;
            }),
            take(1)
        );
    }
}
